import { graphql } from 'gatsby'
import { ThemeConsumer } from 'styled-components'
import { useQueryParam, ModalStateParam } from 'use-query-params'
import Img from 'gatsby-image'
import React from 'react'
import Button from '../components/Button'
import Space from '@rebass/space'
import BuyLicenseForm from '../components/BuyLicenseForm'
import Card from '../components/Card'
import CompanyLogos from '../components/CompanyLogos'
import Container from '../components/Container'
import { Flex, Box } from 'reflexbox'
import GradientWrapper from '../components/GradientWrapper'
import SEO from '../components/SEO'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import PaddleLogo from '../images/vector/paddle.svg'
import SetappLogo from '../images/vector/setapp.svg'
import IconSecurity from '../images/vector/icon-security.svg'
import IconNew from '../images/vector/icon-new.svg'
import IconChevron from '../images/vector/icon-chevron.svg'

const BuyNow = ({ data }) => {
  const [modalState, setModalState] = useQueryParam(
    'thank-you',
    ModalStateParam
  )

  function analyticsDownloadTrial() {
    setModalState('true')
  }

  return (
    <ThemeConsumer>
      {theme => (
        <>
          <SEO title="Pricing • Get started with IconJar" />

          {/* Hero */}
          <Container my={[4, 5]}>
            <Box textAlign={'center'}>
              <h1>Get started</h1>
              <p className="subtitle">
                Ready to get serious about icon management?
              </p>
            </Box>
          </Container>

          {/* Pricing options */}
          <Container mb={[5, 6]}>
            <Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mb={4}>
              <Box width={[1, 1 / 2, 7 / 16]} px={[0, 3, 4]} mb={[3, 0]}>
                <Card as={Box}>
                  <BuyLicenseForm />
                </Card>
              </Box>
              <Box width={[1, 1 / 2, 2 / 5]} px={[0, 3, 4]}>
                <Box mb={4}>
                  <Space mb={3}>
                    <IconSecurity width="25" />
                  </Space>
                  <h4>
                    Securely checkout with{' '}
                    <a href="https://paddle.com">
                      <PaddleLogo
                        width="68"
                        style={{
                          fill:
                            theme.appearance === 'light' ? '#31383C' : '#FFF'
                        }}
                      />
                    </a>
                  </h4>
                  <p>
                    You can trust Paddle to handle your IconJar license purchase
                    and delivery from start to finish, leaving you with peace of
                    mind.
                  </p>
                </Box>
                <Box mb={4}>
                  <Space mb={3}>
                    <IconNew width="32" />
                  </Space>
                  <h4>Receive frequent updates</h4>
                  <p>
                    We love hearing from our users! We actively listen to your
                    feedback to make our product even better.
                  </p>
                </Box>
                <a
                  href="https://go.setapp.com/stp177?refAppID=264&utm_medium=vendor_program&utm_source=IconJar&utm_content=link&utm_campaign=pricing_page_callout&stc=pricing_page_callout"
                  style={{ textDecoration: 'none' }}>
                  <Card p={3} animateonhover="true">
                    <Flex alignItems="center">
                      <Box width={4 / 16} style={{ minWidth: '123px' }} mr={3}>
                        <SetappLogo
                          style={{
                            fill:
                              theme.appearance === 'light' ? '#31383C' : '#FFF'
                          }}
                        />
                      </Box>
                      <Box width={11 / 16} mr={3}>
                        <p>IconJar is also available on Setapp for $9.99/mo.</p>
                      </Box>
                      <Box width={1 / 16} textAlign="right">
                        <IconChevron
                          height="12"
                          style={{
                            fill:
                              theme.appearance === 'light' ? '#748086' : '#FFF'
                          }}
                        />
                      </Box>
                    </Flex>
                  </Card>
                </a>
              </Box>
            </Flex>
          </Container>

          <Container mb={[5, 6]}>
            <Flex justifyContent="center">
              <Box width={[1, 3 / 4]}>
                <CompanyLogos />
              </Box>
            </Flex>
          </Container>

          {/* Footer */}
          <GradientWrapper pt={[5, 6]} style={{ backgroundSize: '100%' }}>
            <Container mb={[5, 6]} textAlign={'center'}>
              <Box
                style={{ width: '128px', marginTop: '-8px' }}
                mx="auto"
                mb={3}>
                <Img
                  fixed={data.appIcon.childImageSharp.fixed}
                  alt="IconJar app icon"
                />
              </Box>
              <h2>Rather try before you buy?</h2>
              <p>Take IconJar for a spin with our free 14-day trial.</p>
              <span>
                <Tippy
                  theme={theme.appearance === 'light' ? 'light' : ''}
                  placement={'bottom'}
                  content={
                    <p>
                      💳 No credit card required
                      <br />
                      💻 Works on macOS 10.14 or newer
                    </p>
                  }>
                  <span style={{ display: 'inline-block' }}>
                    <Button
                      as="a"
                      // href="/download"
                      onClick={analyticsDownloadTrial}
                      colorscheme="white"
                      big="true"
                      className="xs-hide">
                      Download free trial
                    </Button>
                  </span>
                </Tippy>
              </span>
            </Container>
          </GradientWrapper>
        </>
      )}
    </ThemeConsumer>
  )
}

export default BuyNow

export const pageQuery = graphql`
  query {
    appIcon: file(relativePath: { eq: "app-icon@2x.png" }) {
      childImageSharp {
        fixed(width: 128, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
